import React, { Component } from 'react'
import { graphql, navigate } from 'gatsby'
import he from 'he'
import Seo from '../components/seo'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Link from '../utils/link'
import { splitText } from "../utils/split-text"

class AboutPage extends Component {

  render() {
    let page = this.props.data.wpPage

    return (
      <>
        <Seo
          path={page.slug === "home" ? "/" : `/${page.slug}/`}
          title={he.decode(page.seo.title)}
          description={page.seo.metaDesc}
          keywords={page.seo.metaKeywords}
          schema={page.seo.schema.raw}
          bodyClass={page.slug}
        />
        <section className='about__wrapper'>
          <div className='about__content'>
            <h1>{page.blocks.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: page.blocks.content }} />
            <div className='about__links'>
              <div className='about__talk'>
                <h4>Let's talk</h4>
                <ul>
                  {page.blocks.email.link && <li><Link to={page.blocks.email.link}>{page.blocks.email.text}</Link></li> }
                  {page.blocks.phone.link && <li><Link to={page.blocks.phone.link}>{page.blocks.phone.text}</Link></li> }
                </ul>
              </div>
              <div className='about__social'>
                <h4>Connect</h4>
                <ul>
                  { page.blocks.social.map((el, i) => (
                    <li key={i}><Link to={el.link}>{el.text}</Link></li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className='about__team'>
            { page.blocks.team.length > 0 &&
              <ul>
                { page.blocks.team.map((el, i) => (
                  <li key={i}>
                    { el.image?.localFile && <GatsbyImage image={getImage(el.image?.localFile)} alt={el.name} /> }
                    { el.name && <h4>{el.name}</h4> }
                    { el.position && <span>{el.position}</span> }
                  </li>
                ))}
              </ul>
            }
          </div>
          <div className='about__attribute'>
            <p>Design by <Link to='https://www.lbdstudios.com.au/'>LBD STUDIOS</Link> + <Link to='https://atollon.com.au'>Atollon</Link></p>
          </div>
          <button onClick={() => navigate(-1)} type='button' className='about__close'>Close <span className='lines'></span></button>
        </section>
      </>
    )
  }
}

export const aboutQuery = graphql`
  query {
    wpPage(slug: {eq: "about"}) {
      title
      slug
      seo {
        metaDesc
        metaKeywords
        title
        schema {
          raw
        }
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      blocks {
        title
        content
        email {
          text
          link
        }
        phone {
          text
          link
        }
        social {
          text
          link
        }
        team {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  width: 600
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
          name
          position
        }
      }
    }
  }
`

export default AboutPage